import { useTranslation } from "react-i18next";
import classes from "./ServicesIndustries.module.scss";
import ServicesIndustriesItem from "./ServicesIndustriesItem";

import copywritingIllustration from "../../../assets/png/copywriting.png";
import localizationIllustration from "../../../assets/png/localization.png";
import translationIllustration from "../../../assets/png/translation.png";

const ServicesIndustries = () => {
  const { t } = useTranslation();

  const getCorrespondingIllustration = (index) => {
    switch (index) {
      case 0:
        return translationIllustration;
      case 1:
        return localizationIllustration;
      case 2:
        return copywritingIllustration;
      default:
        return copywritingIllustration;
    }
  };

  return (
    <div className={classes["services-industries-container"]}>
      <ul>
        {[0, 1, 2].map((index) => {
          return (
            <ServicesIndustriesItem
              key={t(`SERVICES.INDUSTRIES.SERVICE_${index}.TITLE`).replaceAll(
                " ",
                "-"
              )}
              serviceLiteralRoute={`SERVICES.INDUSTRIES.SERVICE_${index}`}
              illustrationSrc={getCorrespondingIllustration(index)}
            />
          );
        })}
      </ul>
    </div>
  );
};

export default ServicesIndustries;
