import { useTranslation } from "react-i18next";

import classes from "./ServicesIndustriesItem.module.scss";

const ServicesIndustriesItem = ({ serviceLiteralRoute, illustrationSrc }) => {
  const { t } = useTranslation();

  return (
    <li className={classes["services-industry-item"]}>
      <img src={illustrationSrc} alt="" />
      <h3>{t(`${serviceLiteralRoute}.TITLE`)}</h3>
      {[...Array(5).keys()].map((index) => {
        return (
          <p
            key={t(`${serviceLiteralRoute}.DESCRIPTION_${index}`).replaceAll(
              " ",
              "-"
            )}
          >
            {t(`${serviceLiteralRoute}.DESCRIPTION_${index}`)}
          </p>
        );
      })}
    </li>
  );
};

export default ServicesIndustriesItem;
