import classes from "./Hero.module.scss";

import typewriterImageJpeg from "../../../assets/img/florian-klauer-typewriter.jpg";
import typewriterImageWebP from "../../../assets/img/florian-klauer-typewriter.webp";

import { useTranslation } from "react-i18next";
import ShowMoreLink from "./ShowMoreLink";

const Hero = () => {
  const { t } = useTranslation();

  return (
    <div className={classes["hero-container"]}>
      <div className={classes["img-container"]}>
        <picture>
          <source srcSet={typewriterImageWebP} type="image/webp" />
          <source srcSet={typewriterImageJpeg} type="image/jpeg" />
          <img src={typewriterImageJpeg} alt="" />
        </picture>
      </div>
      <div>
        <div className={classes["hero-content-container"]}>
          <h1>
            <span className="animate__animated animate__fadeInRight">
              {t("HERO.NAME")}
            </span>
            <span className="animate__animated animate__fadeIn animate__delay-1s">
              {t("HERO.INDUSTRY")}
            </span>
            <span className="animate__animated animate__fadeIn animate__delay-1s">
              {t("HERO.LANGUAGES")}
            </span>
          </h1>
          <ShowMoreLink />
        </div>
      </div>
    </div>
  );
};

export default Hero;
