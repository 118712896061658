import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import MainContent from "../components/main/MainContent";

const HomePage = () => {
  const { i18n } = useTranslation();
  const { lang } = useParams();

  useEffect(() => {
    if (!lang) {
      i18n.changeLanguage("en");
    } else {
      i18n.changeLanguage(lang);
    }
  }, [i18n, lang]);

  return (
    <>
      <Header />
      <MainContent />
      <Footer />
    </>
  );
};

export default HomePage;
