import { useTranslation } from "react-i18next";
import Carousel from "next-elastic-carousel";

import classes from "./TestimonialsSection.module.scss";
import { useEffect, useState } from "react";

const TestimonialsSection = () => {
  const { t } = useTranslation();
  const [shouldShowArrows, setShouldShowArrows] = useState(
    window.innerWidth > 768
  );

  const items = [
    {
      id: 1,
      title: t("TESTIMONIALS.TESTIMONIAL_0.PERSON"),
      description: [
        t("TESTIMONIALS.TESTIMONIAL_0.DESCRIPTION_0"),
        t("TESTIMONIALS.TESTIMONIAL_0.DESCRIPTION_1"),
        t("TESTIMONIALS.TESTIMONIAL_0.DESCRIPTION_2"),
      ],
    },
    {
      id: 2,
      title: t("TESTIMONIALS.TESTIMONIAL_1.PERSON"),
      description: [
        t("TESTIMONIALS.TESTIMONIAL_1.DESCRIPTION_0"),
        t("TESTIMONIALS.TESTIMONIAL_1.DESCRIPTION_1"),
        t("TESTIMONIALS.TESTIMONIAL_1.DESCRIPTION_2"),
      ],
    },
    {
      id: 3,
      title: t("TESTIMONIALS.TESTIMONIAL_2.PERSON"),
      description: [
        t("TESTIMONIALS.TESTIMONIAL_2.DESCRIPTION_0"),
        t("TESTIMONIALS.TESTIMONIAL_2.DESCRIPTION_1"),
      ],
    },
    {
      id: 4,
      title: t("TESTIMONIALS.TESTIMONIAL_3.PERSON"),
      description: [t("TESTIMONIALS.TESTIMONIAL_3.DESCRIPTION_0")],
    },
    {
      id: 5,
      title: t("TESTIMONIALS.TESTIMONIAL_4.PERSON"),
      description: [
        t("TESTIMONIALS.TESTIMONIAL_4.DESCRIPTION_0"),
        t("TESTIMONIALS.TESTIMONIAL_4.DESCRIPTION_1"),
      ],
    },
    {
      id: 2,
      title: t("TESTIMONIALS.TESTIMONIAL_5.PERSON"),
      description: [
        t("TESTIMONIALS.TESTIMONIAL_5.DESCRIPTION_0"),
        t("TESTIMONIALS.TESTIMONIAL_5.DESCRIPTION_1"),
        t("TESTIMONIALS.TESTIMONIAL_5.DESCRIPTION_2"),
      ],
    },
  ];

  useEffect(() => {
    const handleResize = () => {
      setShouldShowArrows(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <section id="testimonials" className={classes["projects-container"]}>
      <div>
        <h2>{t("TESTIMONIALS.TITLE")}</h2>
        <p>{t("TESTIMONIALS.SUBTITLE")}</p>
        <Carousel showArrows={shouldShowArrows}>
          {items.map((item) => (
            <div key={item.id}>
              <div className={classes["description"]}>
                {item.description.map((paragraph, index) => (
                  <p key={index}>{paragraph}</p>
                ))}
              </div>
              <h3 className={classes["title"]}>{item.title}</h3>
            </div>
          ))}
        </Carousel>
      </div>
    </section>
  );
};

export default TestimonialsSection;
