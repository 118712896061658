import { useEffect, useState } from "react";
import classes from "./Header.module.scss";
import HeaderLogo from "./HeaderLogo";
import LanguageSelector from "./LanguageSelector";

import MainNavigation from "./MainNavigation";

const Header = () => {
  const [scrollY, setY] = useState();

  useEffect(() => {
    const update = (e) => {
      setY(window.scrollY);
    };
    window.addEventListener("scroll", update);
    return () => {
      window.removeEventListener("scroll", update);
    };
  }, []);

  return (
    <header
      className={`${classes["header-container"]} ${
        scrollY > 250 ? classes["scrolled"] : ""
      } animate__animated animate__fadeInDown animate__delay-1s`}
    >
      <div>
        <HeaderLogo />
        <div className={classes["navigation-lang-selection-container"]}>
          <MainNavigation />
          <LanguageSelector />
        </div>
      </div>
    </header>
  );
};

export default Header;
