import classes from "./ShowMoreLink.module.scss";

import arrowDown from "../../../assets/svg/arrow-down.svg";
import { useTranslation } from "react-i18next";

const ShowMoreLink = () => {
  const { t } = useTranslation();

  return (
    <div className={classes["show-more-link"]}>
      <a
        href="#who-i-am"
        className="animate__animated animate__bounceIn animate__delay-2s"
      >
        <img src={arrowDown} alt="" />
        {t("SHOW_MORE_BUTTON")}
      </a>
    </div>
  );
};

export default ShowMoreLink;
