import { useTranslation } from "react-i18next";

import classes from "./Footer.module.scss";
import FooterContact from "./FooterContact";
import FooterLogo from "./FooterLogo";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer id="footer" className={classes["footer-container"]}>
      <div className={classes["footer-max-container"]}>
        <div>
          <FooterLogo />
          <FooterContact />
        </div>
        <aside>
          <p
            dangerouslySetInnerHTML={{
              __html: t("FOOTER.CREDITS"),
            }}
          ></p>
        </aside>
      </div>
    </footer>
  );
};

export default Footer;
