import { useTranslation } from "react-i18next";

import arrowRight from "../../../assets/svg/arrow-right.svg";
import arrowBoth from "../../../assets/svg/arrow-both.svg";

import classes from "./ServicesLanguages.module.scss";

const ServicesLanguages = () => {
  const { t } = useTranslation();

  return (
    <div className={classes["services-intro-container"]}>
      <h2>{t("SERVICES.DESCRIPTION.TITLE")}</h2>
      <p
        dangerouslySetInnerHTML={{
          __html: t("SERVICES.DESCRIPTION.PARAGRAPH_1"),
        }}
      ></p>
      <p>{t("SERVICES.DESCRIPTION.PARAGRAPH_2")}</p>
      <ul>
        <li>
          <p>
            {t("LANGUAGES.SPANISH")}
            <img
              src={arrowBoth}
              alt={t("SERVICES.LANG_COMBOS.INTO_FROM_ALT")}
            />
            {t("LANGUAGES.BASQUE")}
          </p>
        </li>
        <li>
          <p>
            {t("LANGUAGES.ENGLISH")}
            <img src={arrowRight} alt={t("SERVICES.LANG_COMBOS.INTO_ALT")} />
            {t("LANGUAGES.SPANISH")}
          </p>
        </li>
        <li>
          <p>
            {t("LANGUAGES.GERMAN")}
            <img src={arrowRight} alt={t("SERVICES.LANG_COMBOS.INTO_ALT")} />
            {t("LANGUAGES.SPANISH")}
          </p>
        </li>
        <li>
          <p>
            {t("LANGUAGES.ENGLISH")}
            <img src={arrowRight} alt={t("SERVICES.LANG_COMBOS.INTO_ALT")} />
            {t("LANGUAGES.BASQUE")}
          </p>
        </li>
        <li>
          <p>
            {t("LANGUAGES.GERMAN")}
            <img src={arrowRight} alt={t("SERVICES.LANG_COMBOS.INTO_ALT")} />
            {t("LANGUAGES.BASQUE")}
          </p>
        </li>
      </ul>
    </div>
  );
};

export default ServicesLanguages;
