import { useTranslation } from "react-i18next";

import classes from "./DecorationDivider.module.scss";

const DecorationDivider = () => {
  const { t } = useTranslation();
  return (
    <aside className={classes["divider-container"]}>
      <div>
        <p
          dangerouslySetInnerHTML={{
            __html: t("MOTTO"),
          }}
        ></p>
      </div>
    </aside>
  );
};

export default DecorationDivider;
