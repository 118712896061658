import React, { Suspense, useState } from "react";
import { useTranslation } from "react-i18next";
import arrowUpRight from "../../assets/svg/arrow-up-right.svg";
import showIcon from "../../assets/svg/show.svg";

import classes from "./FooterContact.module.scss";

const ContactMeLink = React.lazy(() => import("./ContactMeLink"));

const FooterContact = () => {
  const [emailShown, setEmailShown] = useState(false);

  const { t } = useTranslation();

  const showEmail = () => {
    setEmailShown(true);
  };

  return (
    <div className={classes["footer-contact"]}>
      <h2>{t("FOOTER.CONTACT")}</h2>
      <p>{t("BIDER_BI.LOCATION")}</p>
      <ul>
        <li>
          <a
            href="https://www.linkedin.com/in/mikeljato/"
            target="_blank"
            rel="nofollow noopener noreferrer"
          >
            {t("LINKED_IN")}
            <img src={arrowUpRight} alt="" />
          </a>
        </li>
        <li>
          {emailShown ? (
            <Suspense
              fallback={
                <p>
                  {t("FOOTER.LOADING")} <img src={showIcon} alt="" />
                </p>
              }
            >
              <ContactMeLink />
            </Suspense>
          ) : (
            <button onClick={showEmail}>
              {t("FOOTER.SHOW_EMAIL")}
              <img src={showIcon} alt="" />
            </button>
          )}
        </li>
      </ul>
    </div>
  );
};

export default FooterContact;
