import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import classes from "./LanguageSelector.module.scss";

const LanguageSelector = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

  useEffect(() => {
    setSelectedLanguage(i18n.language);
  }, [i18n.language]);

  function changeLanguage(e) {
    setSelectedLanguage(e.target.value);
    navigate(`/${e.target.value}`);
  }

  return (
    <div className={classes["language-select-container"]}>
      <label htmlFor="language">{t("LANG_SELECTOR.ARIA_LABEL")}</label>
      <select
        name="language"
        id="language"
        onChange={changeLanguage}
        value={selectedLanguage}
      >
        <option value="en">{t("LANGUAGES.ENGLISH")}</option>
        <option value="es">{t("LANGUAGES.SPANISH")}</option>
        <option value="eu">{t("LANGUAGES.BASQUE")}</option>
      </select>
    </div>
  );
};

export default LanguageSelector;
