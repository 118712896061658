import { useTranslation } from "react-i18next";

import classes from "./MyselfSection.module.scss";

import portraitWebP from "../../../assets/img/portrait.webp";
import portraitJpeg from "../../../assets/img/portrait.jpg";

const MyselfSection = () => {
  const { t } = useTranslation();

  return (
    <section id="who-i-am" className={classes["myself-section"]}>
      <div className={classes["myself-content-container"]}>
        <div className={classes["myself-text-container"]}>
          <h2>{t("MYSELF.TITLE")}</h2>
          <p>{t("MYSELF.PARAGRAPH_1")}</p>
          <p>{t("MYSELF.PARAGRAPH_2")}</p>
          <p>{t("MYSELF.PARAGRAPH_3")}</p>
        </div>
        <picture>
          <source srcSet={portraitWebP} type="image/webp" />
          <source srcSet={portraitJpeg} type="image/jpeg" />
          <img src={portraitJpeg} alt="" />
        </picture>
      </div>
    </section>
  );
};

export default MyselfSection;
