import { useTranslation } from "react-i18next";
import classes from "./MainNavigation.module.scss";

const MainNavigation = () => {
  const { t } = useTranslation();

  return (
    <nav className={classes["main-navigation-container"]}>
      <ul>
        <li>
          <a href="#who-i-am">{t("MAIN_NAVIGATION.WHO")}</a>
        </li>
        <li>
          <a href="#services">{t("MAIN_NAVIGATION.SERVICES")}</a>
        </li>
        <li>
          <a href="#projects">{t("MAIN_NAVIGATION.PORTFOLIO")}</a>
        </li>
        <li>
          <a href="#testimonials">{t("MAIN_NAVIGATION.TESTIMONIALS")}</a>
        </li>
      </ul>
    </nav>
  );
};

export default MainNavigation;
