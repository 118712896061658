import classes from "./HeaderLogo.module.scss";

import biderBiLogo from "../../assets/svg/biderbi-logo.svg";

const HeaderLogo = ({backgroundIsDark = false}) => {
  return (
    <p
      className={`${classes["logo"]} ${
        backgroundIsDark ? classes["dark-bg"] : ""
      }`}
    >
      <img src={biderBiLogo} alt="" />
      Bider<span>Bi</span>
    </p>
  );
};

export default HeaderLogo;
