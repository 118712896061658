import classes from "./ServicesSection.module.scss";

import line from "../../../assets/svg/line-1.svg";

import ServicesLanguages from "./ServicesLanguages";
import ServicesIndustries from "./ServicesIndustries";

import { useTranslation } from "react-i18next";

const ServicesSection = () => {
  const { t } = useTranslation();
  return (
    <section id="services" className={classes["services-section"]}>
      <ServicesLanguages />
      <img src={line} alt="" />
      <ServicesIndustries />
      <a className={classes["get-in-touch-link"]} href="#footer">
        {t("SERVICES.SERVICES_LINK")}
      </a>
    </section>
  );
};

export default ServicesSection;
