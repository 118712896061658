import DecorationDivider from "./decoration/DecorationDivider";
import Hero from "./hero/Hero";
import MyselfSection from "./myself/MyselfSection";
import ProjectsSection from "./projects/ProjectsSection";
import ServicesSection from "./services/ServicesSection";
import TestimonialsSection from "./testimonials/TestimonialsSection";

const MainContent = () => {
  return (
    <main>
      <Hero />
      <MyselfSection />
      <ServicesSection />
      <DecorationDivider />
      <ProjectsSection />
      <TestimonialsSection />
    </main>
  );
};

export default MainContent;
