import { useTranslation } from "react-i18next";
import line from "../../../assets/svg/line-1.svg";

import classes from "./ProjectsSection.module.scss";

const ProjectsSection = () => {
  const { t } = useTranslation();

  return (
    <section id="projects" className={classes["projects-container"]}>
      <div>
        <h2>{t("PROJECTS.TITLE")}</h2>
        <p>{t("PROJECTS.DESCRIPTION")}</p>
        <ol className={classes["projects-list-container"]}>
          {[1, 2, 3, 4].map((index) => {
            return (
              <li key={`PROJECT_${index - 1}`}>
                <div
                  className={index % 2 === 0 ? classes["even"] : classes["odd"]}
                >
                  <span aria-hidden={true}>
                    {index}
                    <span>&nbsp;.</span>
                  </span>
                  <p>{t(`PROJECTS.PROJECT_${index - 1}.DESCRIPTION`)}</p>
                </div>
                {index !== 4 && <img src={line} alt="" />}
              </li>
            );
          })}
        </ol>
      </div>
    </section>
  );
};

export default ProjectsSection;
