import classes from "./FooterLogo.module.scss";

import HeaderLogo from "../header/HeaderLogo";
import { useTranslation } from "react-i18next";

const FooterLogo = () => {
  const { t } = useTranslation();

  return (
    <div className={classes["personal-info-container"]}>
      <HeaderLogo backgroundIsDark={true} />
      <p>{t("BIDER_BI.SUBTITLE")}</p>
    </div>
  );
};

export default FooterLogo;
